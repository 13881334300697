import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import fontStyles from '../fontStyles';
import { mobileStartBreakpoint } from '@/materialUi/theme';

const useStyles = makeStyles((theme: Theme) => createStyles({
  centeredContainerWrapper: {
    ...fontStyles.centeredContainerWrapper
  },
  centeredContainer: {
    ...fontStyles.centeredContainer,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.centeredContainerMobile
    }
  }
}));

interface CenteredContainerProps{
  children : React.ReactNode;
}

function CenteredContainer(props : CenteredContainerProps) : JSX.Element {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={`${classes.centeredContainerWrapper} js-focus-visible`}>
      <div className={classes.centeredContainer}>
        {children}
      </div>
    </div>
  );
}

export default CenteredContainer;
